// import axios from 'axios';
import VideosFinder from '../../apis/VideosFinder';

import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Main from '../../components/Main';
// import Navigation from '../../components/Navigation';


import styles from './Homepage.module.css';

const Homepage = () => {

	const navigate = useNavigate();
	const [movies, setMovies] = useState([]);
	const [count, setCount] = useState(1);
	// const [backgroundInterval, setBackgroundInterval] = useState(4);
	const backgroundInterval = 4;

	// Getting all images
	useEffect(() => {
		document.title = 'Julius Sičiūnas';
		async function makeFetch() {
			try {
				const data = await VideosFinder.get("/");
				setMovies(prev => data.data.movies);
				if(!data.data.movies.length) return navigate('/movies');
				
			} catch (err) {
				throw new Error('Erro with img');
			}
		}
		makeFetch();
	}, [navigate]);

	useEffect(() => {
		const countInterval = setInterval(function() {
			setCount(count => count+1);
		}, backgroundInterval*1000);

		if(count > movies.length) setCount(1);

		return () => clearInterval(countInterval);
	}, [count, movies.length]);
	const compare = (a, b ) => new Date(b.createdAt) - new Date(a.createdAt);

	return (
		<Main>
			{movies.length ? movies.sort(compare).map((movie, i) => {
				return <img 
					src={movie.image_max}
					alt={movie.title} 
					className={`${styles.img} ${i+1 === count ? styles.show : ''}`} 
					key={movie.id} 
				/>
			}) : null /*navigate('/movies')*/}
			<div className={styles.mask} onClick={() => navigate(`/movies/${movies[count-1].slug}`)}></div>
				
		</Main>

	);
};

export default Homepage;