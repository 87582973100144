import styles from './Input.module.css';

const Input = ({required = false, pattern=null, label, value = '', setFormData, isError = false, setIsError = null }) => {
	const name = label.toLowerCase();
	const onChangeHandler = e => {
		
		setFormData(form => ({...form, [e.target.name]: e.target.value}) );
		if(isError) {
			setIsError(false)
		}
	}
	
	return (
		<div className={styles.inputGroup}>
			<label htmlFor={name}>{label}</label>
			<input 
				type="text"  
				id={name} 
				name={name} 
				value={value} 
				pattern={pattern} 
				required={required} 
				onChange={e => onChangeHandler(e)} 
				style={{
					color: `${isError ? '#922724' : '#16161D'}`, 
					fontWeight: `${isError ? '900' : '400'}`, 
				}} 
			/>
		</div>
	);
};

export default Input;