import { usePhotos } from '../../hooks/usePhotos';
import Main from '../../components/Main';
import Photos from '../../components/photos/Photos';

const PhotosPage = () => {
	const { photos, isLoading } = usePhotos('/');
	return (
		<Main>
			<Photos photos={photos} isLoading={isLoading}/>
		</Main>
	);
};

export default PhotosPage;