import { useState, useEffect } from 'react';
import PhotosFinder from '../apis/PhotosFinder';

export function usePhotos(query) {
	const [photos, setPhotos] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	
	useEffect(() => {
		document.title = 'Julius Sičiūnas | Photos';
		(async () => {
			try {
				setIsLoading(true);
			  	const data = await PhotosFinder.get(query);
			  	setPhotos(data.data.photos);
			  	setIsLoading(false);
		  	} catch(err) {
		  		console.log(err.message);
		  	} 
		})();
	}, [query]);
	
	return { photos, isLoading };
};

