import styles from './Videos.module.css';
import VideoCard from './VideoCard';

const Videos = ({videos, isLoading}) => {
	const compare = (b, a) => b.order - a.order;

	return (
		<div className={styles.videos}>
			{videos.sort(compare).map(video => <VideoCard video={video} key={video.id}/>)}
		</div>
	);
};

export default Videos;