import { Funnel } from "@phosphor-icons/react";
import styles from './FilterButtons.module.css';

const FilterButtons = ({setVideoType, videoType}) => {
    return (
        <div className={styles.filterButtonsContainer}>
            <button className={`${styles.btn} ${styles.all}`} onClick={() => setVideoType('')}>
                All<Funnel weight={videoType === '' ? 'fill' : null} className={styles.icon} />
            </button>
			<button className={`${styles.btn} ${styles.movie}`} onClick={() => setVideoType('movie')}>
                Movies<Funnel weight={videoType === 'movie' ? 'fill' : null} className={styles.icon} />
            </button>
			<button className={`${styles.btn} ${styles.commercial}`} onClick={() => setVideoType('commercial')}>
                Commercials
                <Funnel weight={videoType === 'commercial' ? 'fill' : null} className={styles.icon} />
            </button>
			<button className={`${styles.btn} ${styles.music}`} onClick={() => setVideoType('music')}>
                Music Videos
                <Funnel weight={videoType === 'music' ? 'fill' : null} className={styles.icon} />
            </button>
        </div>
    );
};

export default FilterButtons;