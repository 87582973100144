import { useState, useEffect } from 'react';
import VideosFinder from '../apis/VideosFinder';

export function useVideo(query) {

	const [video, setVideo] = useState(null);

	const title = 0;
	const byline = 0;
	const portrait = 0;
	const controls = 1;

	useEffect(() => {
		(async () => {
			try {
				const data = await VideosFinder.get(query);
				document.title = data.data.movie ? 'Julius Sičiūnas | ' + data.data.movie.title : 'Page Not Found';
				setVideo(data.data.movie);
			} catch(err) {
				console.log(err.message);
			}
		})();
	}, [query]);

	const paramsString = `?title=${title}&byline=${byline}&portrait=${portrait}&controls=${controls}`;

	return { video, paramsString };
}
	