import styles from './Form.module.css';

const Form = ({children, onSubmitHandler}) => {

	return (
		<form className={styles.form} onSubmit={onSubmitHandler}>
			{children}
		</form>
	);
};

export default Form;
