import Main from '../../components/Main';
import Videos from '../../components/videos/Videos';

import { useVideos }  from '../../hooks/useVideos';

const MoviesPage = ({query}) => {
	const { videos, isLoading } = useVideos(query);
	
    return ( 
    	<>
	        <Main>
	        	<Videos videos={videos} isLoading={isLoading}/>
	        </Main> 
        </>
    );
};

export default MoviesPage;