import styles from './Photos.module.css';
import Photo from './Photo';

const Photos = ({photos, isLoading}) => {
	return (
		<div className={styles.photosContainer}>
			{photos.map(photo => <Photo photo={photo} key={photo.id}/>)}
		</div>
	);
};

export default Photos;