import styles from './UploadFile.module.css';
import { UploadSimple } from "@phosphor-icons/react";
import { useRef } from 'react';

const UploadFile = ({label, setFormData, formData}) => {
	
	const fileBtn = useRef(null);

	return (
		<div className={`${styles.inputGroup} ${styles.fileUploadInput}`}>
			<label className={styles.inputLabel} htmlFor='image'>{label}</label>
			<input ref={fileBtn} type="file" name="image" id='image' accept='image/*' onChange={e => setFormData(form => ({...form, [e.target.name]: e.target.files[0]  }) )} />
		
			<button type='button' onClick={() => fileBtn.current.click()}>
				<UploadSimple className={styles.icon} weight="bold" />
				Choose Image To Upload
			</button>
			<span className={styles.fileName}>{formData.image ? formData.image.name : `No Selected File`}</span>
		</div>
	);
};

export default UploadFile;