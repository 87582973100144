import Main from '../../components/Main';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminFinder from '../../apis/AdminFinder';
import Videos from '../../components/admin/Video/Videos';
import ActionButtons from '../../components/admin/ActionButtons';
import FilterButtons from '../../components/admin/FilterButtons';
import Spinner from '../../components/Spinner';
import Modal from '../../components/admin/Modal/Modal';
import Form from '../../components/admin/Form/Form';
import Input from '../../components/admin/Form/Input';
import Select from '../../components/admin/Form/Select';
import UploadFile from '../../components/admin/Form/UploadFile';
import FormActionButtons from '../../components/admin/Form/FormActionButtons';

const AdminPage = () => {
	const [videos, setVideos] = useState([]);
	const [videoType, setVideoType] = useState('');
	const [toggleVideoPhotos, setToggleVideoPhotos] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [isError, setIsError] = useState(false);
	const [formData, setFormData] = useState({title: '', link: '', director: '', camera: '', color: '', type: 'other', image: ''});
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			const data = await AdminFinder.get('/');
			

			// THIS IS DONT WORK ON LOCALHOST. ONLY IN PROD
			if(!data.data.user) {
				return navigate('/login')
            }

			if(videoType) {
				setVideos(data.data.movies.filter(v => v.type === videoType));
			} else {
				setVideos(data.data.movies);
			}
			setIsLoading(false);
		})();
	}, [isOpen, videoType, navigate]);

	const onDeleteVideo = video_slug => {
		setVideos(prevVideo => prevVideo.filter(v => v.slug !== video_slug));
	};

	const onChangeOrder = (slug, order) => {
		videos.map(v => {
			if(v.slug === slug) v.order = order;
			return v;
		});
		
		setVideos(prev => [...prev]);
	};

	const submitHandler = async e => {
		e.preventDefault();

		try {
			setIsLoading(true)
			await AdminFinder.post('/video', formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});
			setIsOpen(false);

			setIsLoading(false)
			setFormData({type: 'other'});
			
		} catch (err) {
			setIsError(true)
			setIsLoading(false)
			setFormData({title: err.response.data.msg, type: formData.type });
		}
	};

	return (
		<Main>
			{isOpen &&
				<Modal title='New Video' setIsOpen={setIsOpen} isOpen={isOpen} key={'modal'} setFormData={setFormData} >
					<Form onSubmitHandler={submitHandler}>
						<Input label='Title' value={formData.title} setFormData={setFormData} required={true} isError={isError} setIsError={setIsError} />
						<Input label='Link' value={formData.link} setFormData={setFormData} pattern='http.*'/>
						<Input label='Director' value={formData.director} setFormData={setFormData} />
						<Input label='Prod' value={formData.prod} setFormData={setFormData} />
						<Input label='Camera' value={formData.camera} setFormData={setFormData} />
						<Input label='Color' value={formData.color} setFormData={setFormData} />
						<Select label='Video Type' value={formData.type} setFormData={setFormData} />
						<UploadFile label='Image' setFormData={setFormData} formData={formData} />
						<FormActionButtons setFormData={setFormData} isLoading={isLoading} />
					</Form>
				</Modal>
			}

			<ActionButtons 
				setIsOpen={setIsOpen} 
				toggleVideoPhotos={toggleVideoPhotos} 
				setToggleVideoPhotos={setToggleVideoPhotos}
			/>

			{toggleVideoPhotos ? 
				<>
					<FilterButtons setVideoType={setVideoType} videoType={videoType} />
					{!isLoading ? <Videos 
									onChangeOrder={onChangeOrder}
									videos={videos} 
									isLoading={isLoading} 
									deleteVideo={onDeleteVideo} 
								/> : <Spinner />
					}
				</>
				: <h1>Photos</h1>
			}
		</Main>
	);
};

export default AdminPage;