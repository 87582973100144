import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './PageNotFound.module.css';
import Main from '../../components/Main';
// import Navigation from '../../components/Navigation';



const PageNotFound = () => {
	const navigate = useNavigate();
	useEffect(() => {
		document.title = 'Page Not Found 404';
	}, []);
	
	return (
		<Main>
			<div className={styles.notFound}>
				<h1 className={styles.fourofour}>404</h1>
				<p className={styles.pageNotFound}>PAGE NOT FOUND</p>
				<p className={styles.returnHomePage} onClick={() => navigate('/')}>RETURN TO THE HOME PAGE</p>
			</div>
		</Main>
	);
};

export default PageNotFound;