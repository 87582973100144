import styles from './Videos.module.css';
import Video from './Video';
import Spinner from '../../Spinner';

const Videos = ({videos, isLoading, deleteVideo, onChangeOrder }) => {
	const compare = (b, a) => b.order - a.order;
	if(isLoading) return <Spinner />
	
	return (
		<>
			<div className={styles.videoContainer}>
				{videos.sort(compare).map(video => <Video 
					onChangeOrder={onChangeOrder}
					videos={videos}
					video={video} 
					deleteVideo={deleteVideo} 
					key={video.id} 
				/> )}
			</div>
		</>
	)
}

export default Videos;
