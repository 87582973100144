import styles from './FormActionButtons.module.css';

const FormActionButtons = ({setFormData, isLoading}) => {
	
	return (
		<div className={`${styles.inputGroup} ${styles.actionButtons}`}>
			<button className={styles.cancelBtn} type='reset' onClick={() => setFormData({type: 'other'})}>Reset</button>
			<button disabled={isLoading ? true : false} className={styles.submitBtn} type='submit'>
				{isLoading ? 'Loading...' : 'Save'}
			</button>
		</div>
	);
};

export default FormActionButtons;