import styles from './Photo.module.css';

const Photo = ({photo}) => {
	return (
		<div className={styles.img}>
			<a href={`/photos/${photo.image}`}>
				<img alt="img1" src={`/photos/${photo.thumbnail}`} />
		    </a>
		</div>
	);
};

export default Photo;