import styles from './Select.module.css';

const Select = ({label, value, setFormData}) => {
	
	return (
		<div className={styles.inputGroup}>
			<label htmlFor="type">{label}</label>
			<select value={value} id='type' name='type' className={styles.formSelect} onChange={e => setFormData(form => ({...form, [e.target.name]: e.target.value}) )}>
				<option value='empty' disabled>Select</option>
				<option value="other">Other</option>	
				<option value="movie">Movie</option>
				<option value="commercial">Commercial</option>
				<option value="music">Music Video</option>
			</select>
		</div>
	);
};

export default Select;