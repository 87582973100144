import styles from './Modal.module.css';
import { XSquare } from "@phosphor-icons/react";

const Modal = ({children, title, setIsOpen, setFormData}) => {

	const closeModal = () => {
		setFormData({title: '', link: '', director: '', prod: '', camera: '', color: '', type: 'other', image: ''});
		setIsOpen(on => !on);
	}

	return (
		<div className={styles.modal}>
			<div className={styles.overlay} onClick={closeModal}></div>
			<div className={styles.modalContent}>
				<XSquare weight="fill" className={styles.modalCloseIcon} onClick={closeModal} />
				<h1 className={styles.modalTitle}>{title}</h1>
				{children}
			</div>
		</div>
	);
};

export default Modal;