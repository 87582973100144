import styles from './VideoPage.module.css';
import Main from '../../components/Main';

import { useParams, useNavigate } from 'react-router-dom';
import { useVideo } from '../../hooks/useVideo';


const VideoPage = ({query}) => {
	const { slug } = useParams();
	const { video, paramsString } = useVideo(`/${query}/${slug}`);
	const navigate = useNavigate();
	
	return (
			<Main>
				{video && <div className={styles.videoContainer}>

					{video.link.indexOf('http') > -1 && <iframe 
						src={video.source === 'vimeo' ? video.link+paramsString : video.link} 
						className={styles.iFrameResponsive} 
						title={video.title} 
						frameBorder="0" 
						allow="autoplay; fullscreen; picture-in-picture">
					</iframe> }

					<span className={styles.title}>{video.title}</span>

					<div className={styles.videoDetails}>
						{video.director && <div className="director">
							<span className={styles.top}>Director</span>
							<span className={styles.bottom}>{video.director}</span>
						</div>}
						{video.prod && <div className="prod">
							<span className={styles.top}>Prod</span>
							<span className={styles.bottom}>{video.prod}</span>
						</div>}
						{video.camera && <div className="camera">
							<span className={styles.top}>Camera and Lenses</span>
							<span className={styles.bottom}>{video.camera}</span>
						</div>}
						{video.color && <div className="color">
							<span className={styles.top}>Color</span>
							<span className={styles.bottom}>{video.color}</span>
						</div>}
						<div className={styles.back} onClick={() => navigate(-1)}>
							back
						</div>
					</div>
				</div>}
			</Main>

	);
};

export default VideoPage;