import { Link } from 'react-router-dom';
import styles from './VideoCard.module.css';

const VideoCard = ({video}) => {

    return (
    	<>
	        <div className={styles.video}>
	        	<Link to={`${video.slug}`}>
					{/*<img src={`./images/video_images/${video.thumb_big}`} alt={video.title} />*/}
	        	<img src={video.image_med} alt={video.title} />
					<div className={styles.videoDescription}>
						<h1 className={styles.videoTitle}>{video.title}</h1>
	{/*					<span className={styles.moivieDirector}><span>director</span>&nbsp;{video.director}</span>
						<span className={styles.videoCamera}><span>camera & lenses</span>&nbsp;{video.camera}</span>
						<span className={styles.videoColor}><span>color</span>&nbsp;{video.color}</span>*/}
					</div>
				</Link>
			</div>
{/*			<div className={styles.videoDescriptionResponsive}>
				<span style={{color: '#efe9e0'}} className={styles.videoDirector}>
					<span>director</span>&nbsp;{video.director}
				</span>
				<span className={styles.videoCamera}>
					<span>camera & lenses</span>&nbsp;{video.camera}
				</span>
				<span className={styles.videoColor}>
					<span>color</span>&nbsp;{video.color}
				</span>
			</div>*/}
		</>
    );
};

export default VideoCard;