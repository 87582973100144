import { useState, useEffect } from 'react';
import VideosFinder from '../apis/VideosFinder';

export function useVideos(query) {
	const [videos, setVideos] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	
	useEffect(() => {
		switch(query) {
			case 'movies':
				document.title = 'Julius Sičiūnas | Movies';
				break;
			case 'commercials':
				document.title = 'Julius Sičiūnas | Commercials';
				break;
			case 'music-videos':
				document.title = 'Julius Sičiūnas | Music Videos';
				break;
			default:
				document.title = 'Page Not Found 404';
				break;
		}
		
		(async () => {
			try {
				setIsLoading(true);
			  	const data = await VideosFinder.get(query);
			  	setVideos(data.data.movies);
			  	setIsLoading(false);
		  	} catch(err) {
		  		console.log(err.message);
		  	} 
		})();
	}, [query]);
	
	return { videos, isLoading };
};

