import { RadioButton, Trash, ArrowCircleUp, ArrowCircleDown } from "@phosphor-icons/react";
import styles from './Video.module.css';
import AdminFinder from '../../../apis/AdminFinder';

import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

const reducer = (state, action) => {
	
	switch(action.type) {
		case 'is_bgr':
			return {...state, is_bgr: !state.is_bgr}
		case 'is_act': 
			return {...state, is_act: !state.is_act}
		case 'arrow_up':
			return {...state, is_hover_up: action.isHover}
		case 'arrow_down':
			return {...state, is_hover_down: action.isHover}
		default:
			throw new Error('Unknown action');
	}
};

const Video = ({videos, video, deleteVideo, onChangeOrder }) => {
	
	const navigate = useNavigate();
	const [{is_bgr, is_act, is_hover_up, is_hover_down }, dispatch] = useReducer(reducer, {
		is_bgr: video.is_background, 
		is_act: video.active,
		is_hover_up: false,
		is_hover_down: false
	});


	const handleOnOff = async (slug, type) => {
		dispatch({ type });
		let reqOptions = {};

		if(type === 'is_bgr') reqOptions = {is_background: !video.is_background};
		if(type === 'is_act') reqOptions = {active: !video.active};

		try {
 			await AdminFinder.put(`/video/${slug}`, reqOptions);
 		} catch (err) {
 			console.log('Error: ', err)
 			navigate('/login')
 		}
	};

	const deleteCurrentVideo = async (e, slug) => {
		e.target.closest(`.${styles.video}`).classList.add(`${styles.deletedVideo}`);
		try {
			await AdminFinder.delete(`/video/${slug}`);
			setTimeout(() => {
				deleteVideo(slug);
			}, 400);
		} catch (err) {
			console.log('Error: ', err)
			navigate('/login')
		}
	};

	const changeVideoOrder = async (slug, type, order) => {
		const videoLength = videos.filter(v => v.type === type).length;
		
		if(order <= 0) {
			order = 1;
			return;
		} 
		if(order > videoLength) {
			order = videoLength;
			// return;
		}
		
		try {
			await AdminFinder.put(`/video/${slug}`, {order});
			onChangeOrder(slug, order);
		} catch (err) {
			console.log('Error: ', err)
			navigate('/login')
		}
		
	};

	return (
		<div className={styles.video}>
			<div className={`${styles.videoType} ${video.type}`}>{`${video.type === 'music' ? 'music video' : video.type}`}</div>

			<div className={styles.body} onClick={() => navigate(`/mahaga/${video.slug}/edit`)}>
				<img className={styles.img} src={video.image_min} alt={video.title} />
				<div className={styles.title}>{video.title}</div>
			</div>

			<div className={styles.actions}>
				{video.type === 'movie' && <div className={styles.actionBtn} onClick={() => handleOnOff(video.slug, 'is_bgr')}>
					<RadioButton weight={is_bgr ? 'fill' : null} className={styles.icon} />
					<span className={styles.iconText}>background</span>
				</div>}

				<div 
					className={styles.actionBtn} onClick={() => handleOnOff(video.slug, 'is_act')}>
					<RadioButton weight={is_act ? 'fill' : null} className={styles.icon} />
					<span className={styles.iconText}>active</span>
				</div>

				<div className={styles.filterVideoButtons}>
					<ArrowCircleUp 
						className={styles.arrowIcon}
						weight={is_hover_up ? 'fill' : null} 
						onClick={() => changeVideoOrder(video.slug, video.type, video.order-1)}
						onMouseEnter={() => dispatch({ type: 'arrow_up', isHover: true })} 
						onMouseLeave={() => dispatch({ type: 'arrow_up', isHover: false })}
					/>
					<span className={styles.priorNumber}>{video.order}</span>
					<ArrowCircleDown 
						className={styles.arrowIcon}
						weight={is_hover_down ? 'fill' : null} 
						onClick={() => changeVideoOrder(video.slug, video.type, video.order+1)}
						onMouseEnter={() => dispatch({ type: 'arrow_down', isHover: true })} 
						onMouseLeave={() => dispatch({ type: 'arrow_down', isHover: false })}
					/>
				</div>

				<div className={`${styles.actionBtn} ${styles.delete}`} onClick={e => deleteCurrentVideo(e, video.slug)}>
					<Trash className={styles.icon} weight='fill'/>
					<span className={styles.iconText}>delete</span>
				</div>
			</div>
		</div>
	);
};
export default Video;