import styles from './ActionButtons.module.css';
import { Plus } from "@phosphor-icons/react";

const ActionButtons = ({setIsOpen, toggleVideoPhotos, setToggleVideoPhotos}) => {

	return (
		<div className={styles.videoActionsContainer}>
			<div className={styles.btn} onClick={() => setIsOpen(on => !on)}>
				{toggleVideoPhotos ? "New Video" : "Add Photo"}
				<Plus weight="bold" className={styles.icon}/>
			</div>

			<div 
				className={styles.btn} 
				onClick={() => setToggleVideoPhotos(on => !on)}>{
					toggleVideoPhotos ? 'Photos' : 'Videos'
				}
			</div>
		</div>
	);
};

export default ActionButtons;